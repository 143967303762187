<template>
  <base-section
    id="pricing-plan"
    class="text-center"
  >
    <base-section-heading :title="$t('sections.pricingplan.title')">
      {{ $t('sections.pricingplan.text') }}
    </base-section-heading>

    <v-btn-toggle
      v-model="psp"
      background-color="white"
      tile
    >
      <v-btn
        active-class="primary white--text"
        width="125"
      >
        {{ $t('sections.pricingplan.psp') }}
      </v-btn>

      <v-btn
        active-class="primary white--text"
        width="125"
      >
        {{ $t('sections.pricingplan.npsp') }}
      </v-btn>
    </v-btn-toggle>

    <div class="pa-8" />

    <v-container>
      <v-row>
        <v-col
          v-for="(plan, i) in plans"
          :key="i"
          cols="12"
          md="4"
        >
          <price-card v-bind="plan" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPricingPlan',

    components: {
      PriceCard: () => import('@/components/PriceCard'),
    },

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
      psp: 1,
    }),

    computed: {
      plans () {
        return this.internalPlans.map(plan => {
          return {
            ...plan,
            features: plan.features || this.features,
            interval: !this.psp
              ? 'PSP'
              : 'NPSP',
            title: plan.title,
            subtitle: plan.subtitle,
            price: !this.psp
              ? plan.monthly
              : plan.yearly,
            fixed: !this.psp ? plan.fixedPsp : plan.fixedNpsp,
            percent: !this.psp ? plan.percentPsp : plan.percentNpsp,
          }
        })
      },

      features () {
        return this.$t('sections.pricingplan.features')
      },
      internalPlans () {
        return [
          {
            title: this.$t('sections.pricingplan.plan[0].title'),
            subtitle: 'Basic',
            fixedPsp: 0.2,
            fixedNpsp: 0.45,
            percentPsp: 1.1,
            percentNpsp: 2.4,
          },
          {
            title: this.$t('sections.pricingplan.plan[1].title'),
            subtitle: '',
            fixedPsp: 0.2,
            fixedNpsp: 0.45,
            percentPsp: 1.5,
            percentNpsp: 3.9,
          },
        ]
      },
    },
  }
</script>
